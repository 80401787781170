import React, { ReactNode, createContext, useReducer, useContext } from "react"

import websiteReducer, {
  Actions,
  ActivePage,
  initialState,
  Language,
} from "./websiteReducer"

type ContextProps = {
  children: ReactNode
}

type ContextValue = {
  language: string
  activePage: ActivePage
  setEnglish: () => void
  setFrench: () => void
  setActivePage: (currentPage: ActivePage) => void
}

const WebsiteContext = createContext<ContextValue | undefined>(undefined)

export function WebsiteProvider({ children }: ContextProps) {
  const [state, dispatch] = useReducer(websiteReducer, initialState)

  const setEnglish = () => {
    dispatch({
      type: Actions.setLanguage,
      payload: {
        language: Language.en,
      },
    })
  }

  const setFrench = () => {
    dispatch({
      type: Actions.setLanguage,
      payload: {
        language: Language.fr,
      },
    })
  }

  const setActivePage = (currentPage: ActivePage) => {
    dispatch({
      type: Actions.setActivePage,
      payload: {
        activePage: currentPage,
      },
    })
  }

  const value = {
    language: state.language,
    activePage: state.activePage,
    setEnglish,
    setFrench,
    setActivePage,
  }

  return (
    <WebsiteContext.Provider value={value}>{children}</WebsiteContext.Provider>
  )
}

const useLanguage = () => {
  const context = useContext(WebsiteContext)

  if (context === undefined) {
    throw new Error("useLanguage must be used within a WebsiteProvider.")
  }

  return context
}

export default useLanguage
