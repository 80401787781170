import React from "react"

import { WebsiteProvider } from "./context/websiteContext"

interface Props {
  element: React.ReactNode
}

const CombinedProvider = ({ element }: Props) => {
  return <WebsiteProvider>{element}</WebsiteProvider>
}

export default CombinedProvider
