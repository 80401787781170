export interface State {
  language: Language
  activePage: ActivePage
}

export enum Language {
  fr = "fr",
  en = "en"
}

export enum ActivePage {
  home = "home",
  about = "about",
  services = "services",
  contact = "contact"
}

export enum Actions {
  setLanguage = "SET_LANGUAGE",
  setActivePage = "SET_ACTIVE_PAGE"
}

interface Action {
  type: Actions
  payload: Partial<State>
}

export const initialState = {
  language: Language.en,
  activePage: ActivePage.home
}

const websiteReducer = (state: State, action: Action): State => {
  const { type, payload } = action

  switch (type) {

    case Actions.setLanguage:
      // console.log("SET_LANGUAGE", payload)

      return {
        ...state,
        language: payload.language,
      }
    
      case Actions.setActivePage:
          // console.log("SET_ACTIVE_PAGE", payload)

      return {
        ...state,
        activePage: payload.activePage,
      }
    
    default:
      throw new Error(`No case for type ${type} found in websiteReducer.`)
  }
}

export default websiteReducer